import { Button, Upload } from 'antd';
import { uploadFile } from '../../../utils/qiniu';
import { TrpcBase } from '../../../utils/trpc';
import { calcMd5 } from './calcMd5';
import { UploadOutlined } from '@ant-design/icons';

export default function useUploadHook({
	uploadFinishedCallback,
}: { uploadFinishedCallback: (uploadedPath: string) => void }) {
	const getUploadToken = TrpcBase.apartment.getUploadToken.useMutation();

	const uploadFileAction = async (currentFile: any, keyPath: string) => {
		const crcResult = await calcMd5(currentFile);
		const uploadedPath = `${keyPath}/${crcResult}`;
		const uploadToken = await getUploadToken.mutateAsync(uploadedPath);
		await uploadFile(currentFile, uploadToken, uploadedPath);

		uploadFinishedCallback?.(uploadedPath);
	};

	const getDom = (buttonLabel: string, keyPath: string) => {
		return (
			<Upload
				multiple={false}
				maxCount={1}
				showUploadList={false}
				beforeUpload={(file) => {
					return false;
				}}
				onChange={async (value) => {
					const currentFile = value?.file;

					return await uploadFileAction(currentFile, keyPath);
				}}
			>
				<Button icon={<UploadOutlined />}>{buttonLabel}</Button>
			</Upload>
		);
	};

	return { uploadFileAction, getDom };
}
